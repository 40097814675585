body, html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.page-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row; /* Side-by-side layout by default */
  align-items: stretch;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.welcome-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 40px;
  background-color: #ffffff;
  box-sizing: border-box;
}

.image-container {
  width: 100%;
  display: flex; /* Center the image */
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.event-image {
  width: 100%; /* Make the image responsive */
  max-width: 600px; /* Limit width for larger screens */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
}

.welcome-section h1 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #333;
}

.welcome-section p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 15px;
  color: #555;
}

.form-section {
  flex: 1;
  background-color: #d4d4d4;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
}

.form-section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #555;
}

.form-group input {
  width: 100%; /* Make input fields full width */
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

/* Improved button styling */
.form-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-button {
  width: 100%; /* Full width on smaller screens */
  max-width: 300px; /* Limits width on larger screens */
  padding: 12px;
  background: linear-gradient(135deg, #1fe13f, #21ae63);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.submit-button:hover {
  background: linear-gradient(135deg, #21ae63, #1fe13f);
}

/* Responsive Design */
@media (max-width: 768px) {
  .page-container {
    flex-direction: column;
  }

  .welcome-section {
    text-align: center;
    padding: 20px;
  }

  .image-container {
    width: 100%;
    margin-bottom: 15px;
    
  }

  .event-image {
    width: 100%;
    max-width: 300px;
  }

  .form-section {
    padding: 20px;
  }

  .form-section h2 {
    font-size: 1.3rem;
  }

  .form-group input {
    font-size: 1rem;
    padding: 10px;
  }

  .submit-button {
    width: 100%; /* Full width button on smaller screens */
  }
}

@media (max-width: 480px) {
  .welcome-section {
    padding: 10px;
  }

  .image-container {
    width: 90%;
    margin-top: 645px;
  }

  .event-image {
    max-width: 90%;
  }

  .welcome-section h1 {
    font-size: 1.6rem;
    margin-top: 10px;
  }

  .welcome-section p {
    font-size: 0.9rem;
  }

  .form-section {
    padding: 10px;
  }

  .form-section h2 {
    font-size: 1.2rem;
  }

  .form-group label {
    font-size: 0.8rem;
  }

  .form-group input {
    font-size: 0.9rem;
  }

  .submit-button {
    font-size: 1rem;
    padding: 12px;
    width: 100%;
  }
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.close-button {
  margin-top: 15px;
  padding: 10px 20px;
  background: #d9317a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}
