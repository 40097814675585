.user-list {
  max-width: 90%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 60px); /* Dynamic height to fit the viewport */
  overflow-y: auto; /* Enable vertical scrolling */
}

.user-list h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  position:relative;
  top: 0;
  background-color: #f9f9f9;
  padding: 10px 0;
  z-index: 1; /* Keep header above the content */
}

.user-list p {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.5;
}

.error {
  color: red;
  font-weight: bold;
  font-size: 1rem;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns across all screen sizes */
  gap: 20px; /* Space between cards */
  margin-top: 20px;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-align: left;
}

.card h3 {
  margin: 0 0 10px;
  font-size: 1.2rem;
}

.card p {
  margin: 5px 0;
  font-size: 0.9rem;
  line-height: 1.4;
  word-wrap: break-word; /* Break long words */
  overflow-wrap: break-word; /* Break for older browsers */
  max-height: 4.5em; /* Limit height to fit content */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  display: -webkit-box; /* Flexbox for ellipsis */
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical; /* Flex direction */
}


/* Ensure columns adapt better for smaller devices */
@media (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr); /* Still two columns for smaller screens */
    gap: 15px; /* Slightly reduced gap */
  }
}

@media (max-width: 480px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns even on small mobile devices */
    gap: 10px; /* Minimal gap for very small screens */
  }

  .card {
    padding: 10px; /* Adjust padding */
    width: 160px;
  }

  .card p {
    font-size: 0.75rem;
    -webkit-line-clamp: 2; /* Shorter text for smaller devices */
  }
}

.logout-button {
  padding: 8px 15px;
  background-color: #f44336; /* Red color for logout */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

.logout-button:focus {
  outline: none;
}

.feedback-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.feedback-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.feedback-card h3 {
  margin: 0 0 10px;
  font-size: 1.2rem;
}

.feedback-card p {
  margin: 5px 0;
  font-size: 0.9rem;
  line-height: 1.4;
  word-wrap: break-word;
}

/* Responsive adjustments for feedback cards */
@media (max-width: 768px) {
  .feedback-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .feedback-container {
    grid-template-columns: 1fr;
  }
}