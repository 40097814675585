/* Feedback.css */

.feedback-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .feedback-form .form-group {
    margin-bottom: 15px;
  }
  
  .feedback-form label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .feedback-form input[type="text"],
  .feedback-form select,
  .feedback-form textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .feedback-form textarea {
    resize: vertical;
    min-height: 80px;
  }
  
  .feedback-form .submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .feedback-form .submit-button:hover {
    background-color: #45a049;
  }
  
  /* Responsive Styles */
  
  @media (max-width: 768px) {
    .feedback-form {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .feedback-form {
      padding: 10px;
    }
  
    .feedback-form label {
      font-size: 14px;
    }
  
    .feedback-form input[type="text"],
    .feedback-form select,
    .feedback-form textarea {
      font-size: 14px;
    }
  
    .feedback-form .submit-button {
      width: 100%;
      padding: 12px;
    }
  }
  