.signin-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 80px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .signin-form .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .signin-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .signin-form input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .signin-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .signin-button:hover {
    background-color: #0056b3;
  }
  